import React, { useState } from 'react';
import axios from 'axios';
import CameraCapture from './CameraOnCapture';
import Test from './ImageToText';

// TruckLoadingForm Component
const TruckLoadingForm = () => {
  const [activeTab, setActiveTab] = useState('loading');
  const [formData, setFormData] = useState({
    transporterName: '',
    materialName: '',
    receiverPartyName: '',
    vehicleNumber: '',
    gpsImeiNumber: '',
    driverName: '',
    driverContactNumber: '',
    loadingSite: '',
    loadingDateAndTime: '',
    loaderName: '',
    challanRoyaltyNumber: '',
    doNumber: '',
    freight: '',
    qualityOfMaterials: '',
    tpNumber: '',
    grossWeight: '',
    tareWeight: '',
    netMaterialWeight: '',
    loaderMobileNumber: '',
    unloadingSite: '',
    unloadingDateAndTime: '',
    receivedWeight: '',
    shortageOfMaterials: '',
    tds: '',
    advanced: '',
    fuel: {
      inLiters: '',
      price: '',
      pumpName: '',
      stationsChallanNumber: ''
    },
    sealingDate: '',
    sealingPoint: '',
    sealingColour: '',
    totalSealQuantity: '',
    images: {
      gpsImeiPic: '',
      vehicleNumberPlate: '',
      driverPic: '',
      sealingImages: [],
      vehicleImages: []
    },
    company: '',
    createdBy: '',
  });

  const [error, setError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newTruckLoading, setNewTruckLoading] = useState({ details: '' });
  const tabs = ['loading', 'unloading', 'fuel', 'images']; // Define sections
  const [isLoading, setIsLoading] = useState(false);

  const handleNext = () => {
    const currentIndex = tabs.indexOf(activeTab);
    if (currentIndex < tabs.length - 1) {
      setActiveTab(tabs[currentIndex + 1]);
    }
  };

  const handlePrevious = () => {
    const currentIndex = tabs.indexOf(activeTab);
    if (currentIndex > 0) {
      setActiveTab(tabs[currentIndex - 1]);
    }
  };


  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    // Remove non-numeric characters
    const numericValue = value.replace(/\D/g, '');
  
    if (name === "driverContactNumber" && numericValue.length <= 15) {
      setFormData((prevData) => ({
        ...prevData,
        [name]: numericValue,
      }));
    } else if (name !== "driverContactNumber") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleFuelChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      fuel: {
        ...formData.fuel,
        [name]: value
      }
    });
  };
  const [qrCodes, setQrCodes] = useState([]); // Store scanned QR codes
  const handleQrCodeScan = (newQrCode) => {
    setQrCodes((prevCodes) => [...prevCodes, newQrCode]); // Append new QR code to the array
  };
  const handleImagesChange = async (e, field) => {
    const { files } = e.target;
    const fileArray = Array.from(files);
    
    const uploadedImages = [];
  
    for (const file of fileArray) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('upload_preset', 'testpreset'); // Replace with your Cloudinary upload preset
  
      try {
        const response = await axios.post(`https://api.cloudinary.com/v1_1/dlgyhmuxb/image/upload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        uploadedImages.push(response.data.secure_url); // Save the secure URL of the uploaded image
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  
    setFormData((prevData) => ({
      ...prevData,
      images: {
        ...prevData.images,
        [field]: [...prevData.images[field] || [], ...uploadedImages],
      },
    }));
  };

  const handleCreateTruckLoading = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('vahan-token');
    setIsLoading(true);
    try {
      const formDataWithQrCodes = {
        ...formData,
        qrCodes, // Include the qrCodes array in the formData object
      };
      await axios.post(`${process.env.REACT_APP_API_URL}/api/truck/truck-loading`, formDataWithQrCodes, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      debugger;
      setIsLoading(false);
      setNewTruckLoading({ details: 'Truck loading entry created successfully.' });
      setIsModalOpen(true);
      setFormData({
        transporterName: '',
        materialName: '',
        receiverPartyName: '',
        vehicleNumber: '',
        gpsImeiNumber: '',
        driverName: '',
        driverContactNumber: '',
        loadingSite: '',
        loaderName: '',
        challanRoyaltyNumber: '',
        doNumber: '',
        freight: '',
        qualityOfMaterials: '',
        tpNumber: '',
        grossWeight: '',
        tareWeight: '',
        netMaterialWeight: '',
        loaderMobileNumber: '',
        unloadingSite: '',
        receivedWeight: '',
        shortageOfMaterials: '',
        tds: '',
        advanced: '',
        fuel: {
          inLiters: '',
          price: '',
          pumpName: '',
          stationsChallanNumber: ''
        },
        sealingDate: '',
        sealingPoint: '',
        sealingColour: '',
        totalSealQuantity: '',
        images: {
          gpsImeiPic: '',
          vehicleNumberPlate: '',
          driverPic: '',
          sealingImages: [],
          vehicleImages: []
        },
        company: '',
        createdBy: '',
      });
      window.location.reload()
    } catch (err) {
      alert(err.response?.data?.message || 'Error creating truck loading entry')
      setError(err.response?.data?.message || 'Error creating truck loading entry');
      setIsLoading(false);
    }
  };

  const handleSubmit = (e) => {
    handleCreateTruckLoading(e);
  };

  return (
    <div className=" mx-auto p-4  w-full">
     
      {error && <div className="text-red-500">{error}</div>}

      <div className="m-4 flex flex-wrap sm:flex-wrap lg:flex-nowrap">
      {['loading', 'unloading', 'fuel', 'images'].map(tab => (
          <button
            key={tab}
            onClick={() => handleTabClick(tab)}
            className={`tab py-2 px-4 m-2 rounded-lg transition-colors duration-200 ease-in-out w-full sm:w-[160px]  ${
              activeTab === tab ? 'bg-indigo-500 text-white' : 'bg-gray-200 text-gray-700 hover:bg-blue-100'
            }`}
          >
            {tab.charAt(0).toUpperCase() + tab.slice(1)} Details
          </button>
        ))}
      </div>
    

      <form onSubmit={handleSubmit} className="mt-4">
        {activeTab === 'loading' && (
          <div className=' justify-center lg:flex-wrap sm:flex sm:flex-wrap'>
            <input type="text" name="transporterName" placeholder="Transporter Name" value={formData.transporterName} onChange={handleChange} required className="mb-2 p-2 border rounded w-full" />
            <input type="text" name="materialName" placeholder="Material Name" value={formData.materialName} onChange={handleChange} required className="mb-2 p-2 border rounded w-full" />
            <input type="text" name="receiverPartyName" placeholder="Receiver Party Name" value={formData.receiverPartyName} onChange={handleChange} required className="mb-2 p-2 border rounded w-full" />
            <input type="text" name="vehicleNumber" placeholder="Vehicle Number" value={formData.vehicleNumber} onChange={handleChange} required className="mb-2 p-2 border rounded w-full" />
            <input type="text" name="gpsImeiNumber" placeholder="GPS IMEI Number" value={formData.gpsImeiNumber} onChange={handleChange} className="mb-2 p-2 border rounded w-full" />
            <input type="text" name="driverName" placeholder="Driver Name" value={formData.driverName} onChange={handleChange} className="mb-2 p-2 border rounded w-full" />
            <input 
  type="tel" 
  name="driverContactNumber" 
  placeholder="Driver Contact Number" 
  value={formData.driverContactNumber} 
  onChange={handleChange} 
  className="mb-2 p-2 border rounded w-full" 
  minLength={10} 
  maxLength={15} // Optional: limit to a maximum length
/>

{formData.driverContactNumber.length > 0 && formData.driverContactNumber.length < 10 && (
  <p className="text-red-500">Phone number must be at least 10 digits.</p>
)}
            <input type="text" name="loadingSite" placeholder="Loading Site" value={formData.loadingSite} onChange={handleChange} className="mb-2 p-2 border rounded w-full" />
            <input type="text" name="loaderName" placeholder="Loader Name" value={formData.loaderName} onChange={handleChange} className="mb-2 p-2 border rounded w-full" />
            <input type="text" name="challanRoyaltyNumber" placeholder="Challan Royalty Number" value={formData.challanRoyaltyNumber} onChange={handleChange} className="mb-2 p-2 border rounded w-full" />
            <input type="text" name="doNumber" placeholder="DO Number" value={formData.doNumber} onChange={handleChange} className="mb-2 p-2 border rounded w-full" />
            <input type="Number" name="freight" placeholder="Freight" value={formData.freight} onChange={handleChange} className="mb-2 p-2 border rounded w-full" />
            <input type="text" name="qualityOfMaterials" placeholder="Quality of Materials" value={formData.qualityOfMaterials} onChange={handleChange} className="mb-2 p-2 border rounded w-full" />
            <input type="text" name="tpNumber" placeholder="TP Number" value={formData.tpNumber} onChange={handleChange} className="mb-2 p-2 border rounded w-full" />
            <input type="text" name="grossWeight" placeholder="Gross Weight" value={formData.grossWeight} onChange={handleChange} className="mb-2 p-2 border rounded w-full" />
            <input type="text" name="tareWeight" placeholder="Tare Weight" value={formData.tareWeight} onChange={handleChange} className="mb-2 p-2 border rounded w-full" />
            <input type="text" name="netMaterialWeight" placeholder="Net Material Weight" value={formData.netMaterialWeight} onChange={handleChange} className="mb-2 p-2 border rounded w-full" />
            <input type="text" name="loaderMobileNumber" placeholder="Loader Mobile Number" value={formData.loaderMobileNumber} onChange={handleChange} className="mb-2 p-2 border rounded w-full" />
          </div>
        )}

        {activeTab === 'unloading' && (
          <div>
            <input type="text" name="unloadingSite" placeholder="Unloading Site" value={formData.unloadingSite} onChange={handleChange} className="mb-2 p-2 border rounded" readOnly  />
            <input type="datetime-local" name="unloadingDateAndTime" value={formData.unloadingDateAndTime} onChange={handleChange} className="mb-2 p-2 border rounded" readOnly />
            <input type="text" name="receivedWeight" placeholder="Received Weight" value={formData.receivedWeight} onChange={handleChange} className="mb-2 p-2 border rounded" readOnly />
            <input type="text" name="shortageOfMaterials" placeholder="Shortage of Materials" value={formData.shortageOfMaterials} onChange={handleChange} className="mb-2 p-2 border rounded" readOnly />
            <input type="text" name="tds" placeholder="TDS" value={formData.tds} onChange={handleChange} className="mb-2 p-2 border rounded" readOnly />
            <input type="text" name="advanced" placeholder="Advanced" value={formData.advanced} onChange={handleChange} className="mb-2 p-2 border rounded" readOnly />
          </div>
        )}

        {activeTab === 'fuel' && (
          <div>
            <input type="text" name="inLiters" placeholder="Fuel in Liters" value={formData.fuel.inLiters} onChange={handleFuelChange} className="mb-2 p-2 border rounded" />
            <input type="text" name="price" placeholder="Fuel Price" value={formData.fuel.price} onChange={handleFuelChange} className="mb-2 p-2 border rounded" />
            <input type="text" name="pumpName" placeholder="Pump Name" value={formData.fuel.pumpName} onChange={handleFuelChange} className="mb-2 p-2 border rounded" />
            <input type="text" name="stationsChallanNumber" placeholder="Stations Challan Number" value={formData.fuel.stationsChallanNumber} onChange={handleFuelChange} className="mb-2 p-2 border rounded" />
          </div>
        )}

        {activeTab === 'images' && (
          <div className="space-y-4">
          <div className="flex flex-col">
            <label htmlFor="gpsImeiPic" className="text-sm font-medium mb-1">
              Upload GPS IMEI Picture
            </label>
            <input
              id="gpsImeiPic"
              type="file"
              onChange={(e) => handleImagesChange(e, "gpsImeiPic")}
              className="mb-2 w-full sm:w-auto p-2 border rounded"
            />
          </div>
        
          <div className="flex flex-col">
            <label htmlFor="vehicleNumberPlate" className="text-sm font-medium mb-1">
              Upload Vehicle Number Plate
            </label>
            <input
              id="vehicleNumberPlate"
              type="file"
              onChange={(e) => handleImagesChange(e, "vehicleNumberPlate")}
              className="mb-2 w-full sm:w-auto p-2 border rounded"
            />
          </div>
        
          <div className="flex flex-col">
            <label htmlFor="driverPic" className="text-sm font-medium mb-1">
              Upload Driver's Picture
            </label>
            <input
              id="driverPic"
              type="file"
              onChange={(e) => handleImagesChange(e, "driverPic")}
              className="mb-2 w-full sm:w-auto p-2 border rounded"
            />
          </div>
        
          <div className="flex flex-col">
            <label htmlFor="sealingImages" className="text-sm font-medium mb-1">
              Upload Sealing Images (Multiple)
            </label>
            <input
              id="sealingImages"
              type="file"
              multiple
              onChange={(e) => handleImagesChange(e, "sealingImages")}
              className="mb-2 w-full sm:w-auto p-2 border rounded"
            />
          </div>
        
          <div className="flex flex-col">
            <label htmlFor="vehicleImages" className="text-sm font-medium mb-1">
              Upload Vehicle Images (Multiple)
            </label>
            <input
              id="vehicleImages"
              type="file"
              multiple
              onChange={(e) => handleImagesChange(e, "vehicleImages")}
              className="mb-2 w-full sm:w-auto p-2 border rounded"
            />
          </div>
        
          <div>
            <label className="text-sm font-medium mb-1">
              Capture Additional Images
            </label>
            <CameraCapture />
          </div>
          <div>
          <Test onQrCodeScan={handleQrCodeScan} /> {/* Pass the function to Test component */}
          </div>
        </div>
        
        )}
     
        {/* <button type="submit" className="mt-4 p-2 bg-blue-500 text-white rounded w-full lg:w-full sm:w-auto">Submit</button>
      </form> */}
      <div className="flex justify-between mt-4">
        {tabs.indexOf(activeTab) > 0 && (
          <button
            type="button"
            onClick={handlePrevious}
            className="p-2 bg-gray-500 text-white rounded"
          >
            Previous
          </button>
        )}
        {tabs.indexOf(activeTab) < tabs.length - 1 && (
          <button
            type="button"
            onClick={handleNext}
            className="p-2 bg-blue-500 text-white rounded"
          >
            Next
          </button>
        )}
        {tabs.indexOf(activeTab) === tabs.length - 1 && (
           <button
           type="submit"
           className={`p-2 bg-green-500 text-white rounded ${
             isLoading ? "cursor-wait" : ""
           }`}
           disabled={isLoading}
         >
           {isLoading ? (
             <div className="animate-spin border-t-4 border-white w-5 h-5 rounded-full border-solid"></div>
           ) : (
             "Submit"
           )}
         </button>
        )}
      </div>
    </form>

      {isModalOpen && (
        <div className="modal fixed inset-0 flex items-center justify-center z-50">
          <div className="modal-overlay absolute inset-0 bg-black opacity-50"></div>
          <div className="modal-content bg-white rounded-lg p-6 z-10">
            <h2 className="text-lg font-bold">{newTruckLoading.details}</h2>
            <button
              onClick={() => setIsModalOpen(false)}
              className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TruckLoadingForm;
