import React, { useState } from "react";
import { Scanner } from "@yudiel/react-qr-scanner";
import jsQR from "jsqr"; // jsQR is a library to scan QR codes from images

const Test = ({ onQrCodeScan }) => {
  const [scannedResults, setScannedResults] = useState(""); // Store scanned QR codes

  // Handle file upload and scanning of the uploaded image
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const image = new Image();
        image.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          canvas.width = image.width;
          canvas.height = image.height;
          ctx.drawImage(image, 0, 0);
          const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
          const code = jsQR(imageData.data, canvas.width, canvas.height);
          if (code) {
            setScannedResults((prevResults) => prevResults + "\n" + code.data); // Append to results
            onQrCodeScan(code.data); // Notify parent about the new QR code
          } else {
            setScannedResults("No QR code found.");
          }
        };
        image.src = reader.result;
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div style={{ textAlign: "center", marginTop: "20px" }}>
      <h1>QR Code Scanner</h1>

      {/* QR Scanner with animation */}
      <div className="scanner-container" style={{ marginBottom: "20px" }}>
        <Scanner
          onScan={(data) => {
            if (data) {
              setScannedResults((prevResults) => prevResults + "\n" + data[0].rawValue); // Append to results
              onQrCodeScan(data[0].rawValue); // Notify parent about the new QR code
            }
          }}
          onError={(err) => console.error("Error while scanning:", err)}
          style={{ width: "100%", maxWidth: "500px", height: "auto" }}
        />
      </div>

      {/* Upload Image Button */}
      <div>
        <input
          type="file"
          accept="image/*"
          onChange={handleImageUpload}
          style={{ marginBottom: "20px" }}
        />
      </div>

      {/* Display Scanned QR Codes in a Text Area */}
      <textarea
        value={scannedResults}
        readOnly
        rows={10}
        cols={50}
        style={{ width: "100%", maxWidth: "500px", height: "auto", marginTop: "20px" }}
      />
    </div>
  );
};

export default Test;
