import React, { useState, useEffect } from "react";
import axios from "axios";
import Edit from "./images/edit.svg";
import Delete from "./images/delete.svg";
import { FaEye, FaPlus } from "react-icons/fa6";
import { IoSearch } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { FaFileCsv, FaFileExcel, FaFilePdf, FaPrint } from "react-icons/fa";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import EditTruckLoadingModal from "./EditTruckLoadingModal";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

const TruckLoadingTable = ({ setIsModalOpen }) => {
  const [filteredLoadings, setFilteredLoadings] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [error, setError] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [statusFilter, setStatusFilter] = useState("");
  const [filter, setFilter] = useState("all"); // Initial filter set to "all"

  const [totalPages, setTotalPages] = useState(0);
  const limit = 10; // Number of items per page
  const [selectedLoadingId, setSelectedLoadingId] = useState(null);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  // Fetch truck loading details on component mount and when currentPage changes
  useEffect(() => {
    fetchTruckLoadingDetails();
  }, [searchTerm, currentPage]);

  const fetchTruckLoadingDetails = async () => {
    const token = localStorage.getItem("vahan-token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/truck/truck-loadings`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            page: currentPage,
            limit: limit,
            searchTerm,
          },
        }
      );
      setFilteredLoadings(response.data.truckLoadings);
      setTotalPages(Math.ceil(response.data.total / limit));
      return response.data.truckLoadings;
    } catch (err) {
      setError(
        err.response?.data?.message || "Error fetching truck loading details"
      );
    }
  };
  const filteredData = filteredLoadings.filter((loading) => {
    if (filter === "recentlyCompleted") {
      return loading.status === "completed"; // Assuming status is a string like 'Completed'
    } else if (filter === "recentlyRunning") {
      return loading.status === "running"; // Assuming status is 'Running'
    }
    return true; // If 'all' is selected, show all data
  });
  
  const fetchFilteredLoadings = async () => {
    const token = localStorage.getItem("vahan-token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/truck/filterByDate`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            fromDate,
            toDate,
            page: currentPage,
            limit: limit,
          },
        }
      );
      setFilteredLoadings(response.data.truckLoadings);
      setTotalPages(Math.ceil(response.data.total / limit));
    } catch (err) {
      setError(
        err.response?.data?.message ||
          "Error fetching filtered truck loading details"
      );
    }
  };

  const handleFilter = () => {
    if (!fromDate || !toDate) {
      setError("Both from and to dates are required.");
      return;
    }
    setError(""); // Clear error
    setCurrentPage(1); // Reset to first page on new filter
    fetchFilteredLoadings();
  };

  const exportToExcel = async () => {
    try {
      const dataToExport =
        fromDate || toDate ? await fetchFilteredData() : await fetchAllData();

      if (!dataToExport || dataToExport.length === 0) {
        alert("No data to export");
        return;
      }

      const formattedData = dataToExport.map((record) => ({
        vehicleNumber: record.vehicleNumber,
        transporterName: record.transporterName,
        materialName: record.materialName,
        receiverPartyName: record.receiverPartyName,
        gpsImeiNumber: record.gpsImeiNumber,
        driverName: record.driverName,
        driverContactNumber: record.driverContactNumber,
        loadingSite: record.loadingSite,
        loadingDateAndTime: record.loadingDateAndTime,
        loaderName: record.loaderName,
        challanRoyaltyNumber: record.challanRoyaltyNumber,
        doNumber: record.doNumber,
        freight: record.freight,
        qualityOfMaterials: record.qualityOfMaterials,
        tpNumber: record.tpNumber,
        grossWeight: record.grossWeight,
        tareWeight: record.tareWeight,
        netMaterialWeight: record.netMaterialWeight,
        loaderMobileNumber: record.loaderMobileNumber,
        unloadingSite: record.unloadingSite,
        unloadingDateAndTime: record.unloadingDateAndTime,
        receivedWeight: record.receivedWeight,
        shortageOfMaterials: record.shortageOfMaterials,
        tds: record.tds,
        advanced: record.advanced,
        fuelInLiters: record.fuel.inLiters,
        fuelPrice: record.fuel.price,
        fuelPumpName: record.fuel.pumpName,
        fuelStationsChallanNumber: record.fuel.stationsChallanNumber,
        sealingDate: record.sealingDate,
        sealingPoint: record.sealingPoint,
        sealingColour: record.sealingColour,
        totalSealQuantity: record.totalSealQuantity,
        // gpsImeiPic: record.images.gpsImeiPic.join('; '), // Join array with a separator
        // vehicleNumberPlate: record.images.vehicleNumberPlate.join('; '), // Join array with a separator
        // driverPic: record.images.driverPic.join('; '), // Join array with a separator
        // sealingImages: record.images.sealingImages.join('; '), // Join array with a separator
        // vehicleImages: record.images.vehicleImages.join('; '), // Join array with a separator
        status: record.status,
        companyName: record.company.name, // Add company name
        createdByName: record.createdBy.username, // Add created by name
        comment: record.comment,
        reason: record.reason,
        commentedAt: record.commentedAt,
        createdAt: record.createdAt,
        updatedAt: record.updatedAt,
        _id: record._id,
      }));

      // Create a new workbook and add a worksheet
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(formattedData);

      // Highlight header row in yellow and set text color to red
      const headerCellStyles = {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "FFFF00" }, // Yellow background color for header
        },
        font: {
          bold: true,
          color: { rgb: "FF0000" }, // Red font color
        },
      };

      // Apply styles to the header row
      const headers = Object.keys(formattedData[0]);
      headers.forEach((header, index) => {
        const cellAddress = XLSX.utils.encode_cell({ r: 0, c: index });
        if (!worksheet[cellAddress]) worksheet[cellAddress] = {}; // Initialize cell if not exists
        worksheet[cellAddress].s = headerCellStyles; // Set style
      });

      // Set column widths
      const colWidths = [
        { wch: 30 }, // _id
        { wch: 20 }, // transporterName
        { wch: 20 }, // materialName
        { wch: 20 }, // receiverPartyName
        { wch: 20 }, // vehicleNumber
        { wch: 20 }, // gpsImeiNumber
        { wch: 20 }, // driverName
        { wch: 20 }, // driverContactNumber
        { wch: 20 }, // loadingSite
        { wch: 20 }, // loadingDateAndTime
        { wch: 20 }, // loaderName
        { wch: 20 }, // challanRoyaltyNumber
        { wch: 20 }, // doNumber
        { wch: 20 }, // freight
        { wch: 20 }, // qualityOfMaterials
        { wch: 20 }, // tpNumber
        { wch: 20 }, // grossWeight
        { wch: 20 }, // tareWeight
        { wch: 20 }, // netMaterialWeight
        { wch: 20 }, // loaderMobileNumber
        { wch: 20 }, // unloadingSite
        { wch: 20 }, // unloadingDateAndTime
        { wch: 20 }, // receivedWeight
        { wch: 20 }, // shortageOfMaterials
        { wch: 20 }, // tds
        { wch: 20 }, // advanced
        { wch: 20 }, // fuel.inLiters
        { wch: 20 }, // fuel.price
        { wch: 20 }, // fuel.pumpName
        { wch: 20 }, // fuel.stationsChallanNumber
        { wch: 20 }, // sealingDate
        { wch: 20 }, // sealingPoint
        { wch: 20 }, // sealingColour
        { wch: 20 }, // totalSealQuantity
        { wch: 20 }, // images.gpsImeiPic
        { wch: 20 }, // images.vehicleNumberPlate
        { wch: 20 }, // images.driverPic
        { wch: 20 }, // images.sealingImages
        { wch: 20 }, // images.vehicleImages
        { wch: 20 }, // status
        { wch: 20 }, // companyName
        { wch: 20 }, // createdByName
        { wch: 20 }, // comment
        { wch: 20 }, // reason
        { wch: 20 }, // commentedAt
        { wch: 20 }, // createdAt
        { wch: 20 }, // updatedAt
      ];

      worksheet["!cols"] = colWidths; // Set the column widths

      // Append the worksheet to the workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, "Truck Loadings");

      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      saveAs(blob, "truck_loadings.xlsx");
    } catch (error) {
      console.error("Error exporting to Excel:", error);
      alert("An error occurred while exporting data to Excel.");
    }
  };

  const exportToCSV = async () => {
    try {
      const dataToExport =
        fromDate || toDate ? await fetchFilteredData() : await fetchAllData();

      if (!dataToExport || dataToExport.length === 0) {
        alert("No data to export");
        return;
      }

      // Map the data to include all necessary fields
      const formattedData = dataToExport.map((record) => ({
        _id: record._id,
        transporterName: record.transporterName,
        materialName: record.materialName,
        receiverPartyName: record.receiverPartyName,
        vehicleNumber: record.vehicleNumber,
        gpsImeiNumber: record.gpsImeiNumber,
        driverName: record.driverName,
        driverContactNumber: record.driverContactNumber,
        loadingSite: record.loadingSite,
        loadingDateAndTime: record.loadingDateAndTime,
        loaderName: record.loaderName,
        challanRoyaltyNumber: record.challanRoyaltyNumber,
        doNumber: record.doNumber,
        freight: record.freight,
        qualityOfMaterials: record.qualityOfMaterials,
        tpNumber: record.tpNumber,
        grossWeight: record.grossWeight,
        tareWeight: record.tareWeight,
        netMaterialWeight: record.netMaterialWeight,
        loaderMobileNumber: record.loaderMobileNumber,
        unloadingSite: record.unloadingSite,
        unloadingDateAndTime: record.unloadingDateAndTime,
        receivedWeight: record.receivedWeight,
        shortageOfMaterials: record.shortageOfMaterials,
        tds: record.tds,
        advanced: record.advanced,
        fuelInLiters: record.fuel.inLiters,
        fuelPrice: record.fuel.price,
        fuelPumpName: record.fuel.pumpName,
        fuelStationsChallanNumber: record.fuel.stationsChallanNumber,
        sealingPoint: record.sealingPoint,
        sealingColour: record.sealingColour,
        totalSealQuantity: record.totalSealQuantity,
        gpsImeiPic: record.images.gpsImeiPic.join("; "), // Join array with a separator
        vehicleNumberPlate: record.images.vehicleNumberPlate.join("; "), // Join array with a separator
        driverPic: record.images.driverPic.join("; "), // Join array with a separator
        sealingImages: record.images.sealingImages.join("; "), // Join array with a separator
        vehicleImages: record.images.vehicleImages.join("; "), // Join array with a separator
        status: record.status,
        companyName: record.company.name, // Add company name
        createdByName: record.createdBy.username, // Add created by name
        comment: record.comment,
        reason: record.reason,
        commentedAt: record.commentedAt,
        createdAt: record.createdAt,
        updatedAt: record.updatedAt,
      }));

      // Generate CSV content from the formatted data
      const csvContent = XLSX.utils.sheet_to_csv(
        XLSX.utils.json_to_sheet(formattedData)
      );

      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

      saveAs(blob, "truck_loadings.csv");
    } catch (error) {
      console.error("Error exporting to CSV:", error);
      alert("An error occurred while exporting data to CSV.");
    }
  };

  const fetchFilteredData = async () => {
    const token = localStorage.getItem("vahan-token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/truck/filterByDate`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            fromDate,
            toDate,
          },
        }
      );
      return response.data.truckLoadings;
    } catch (error) {
      console.error("Error fetching filtered data:", error);
      return [];
    }
  };

  const fetchAllData = async () => {
    const token = localStorage.getItem("vahan-token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/truck/export-truck-loadings`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching all data:", error);
      alert("Access Denied please contact your company");
      return [];
    }
  };

  const handleEdit = (id) => {
    setSelectedLoadingId(id);
    setEditModalOpen(true); // Open the modal
  };

  const refreshData = () => {
    // Re-fetch data after editing
    fetchTruckLoadingDetails();
  };
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleDelete = (id) => {
    alert("Delete Not allowed");
    // Add your delete logic here
    // console.log(`Delete loading with ID: ${id}`);
  };
  const exportToPDF = async () => {
    try {
      const dataToExport =
      fromDate || toDate ? await fetchFilteredData() : await fetchAllData();

    if (!dataToExport || dataToExport.length === 0) {
      alert("No data to export");
      return;
    }
      // Initialize jsPDF
      const doc = new jsPDF();

      // Title for the document
      doc.text("Truck Loadings Bill", 14, 15);

      // Prepare the table header
      const tableColumn = [
        "Sr No",
        "Vehicle Number",
        "Transport Name",
        "gpsImeiNumber",
        "Net Material Weight",
        "Loading Date",
        "Unloading Date",
        "Loading Site",
        "Unloading Site",
      ];

      const tableRows = dataToExport.map((row, index) => [
        index + 1, // Sr No
        row.vehicleNumber,
        row.transporterName,
        row.gpsImeiNumber,
        row.netMaterialWeight,
        new Date(row.createdAt).toLocaleString("en-IN", {
          timeZone: "Asia/Kolkata",
        }),
        row.updatedAt
          ? new Date(row.updatedAt).toLocaleString("en-IN", {
              timeZone: "Asia/Kolkata",
            })
          : "Truck is running",
        row.loadingSite,
        row.unloadingSite? row.unloadingSite:"Truck is running",
      ]);

      // Create the table
      doc.autoTable({
        head: [tableColumn],
        body: tableRows,
        startY: 25,
        theme: "grid",
        styles: { cellPadding: 2, fontSize: 10 },
        margin: { top: 25, left: 10, right: 10 },
      });

      // Add the verified stamp image at the bottom of the page
     
      const pageHeight = doc.internal.pageSize.height;
      const imgWidth = 50;
      const imgHeight = 50;

      // doc.addImage(
      //   imgData,
      //   "PNG",
      //   80,
      //   pageHeight - imgHeight - 10,
      //   imgWidth,
      //   imgHeight
      // );

      // Save the PDF
      doc.save("truck_loadings_bill.pdf");
    } catch (error) {
      console.error("Error exporting to PDF:", error);
      alert("An error occurred while exporting data to PDF.");
    }
  };

  return (
    <>
      <div className="p-2 bg-white w-full mb-10">
        <div className="flex justify-between px-4 py-4 rounded-tl-md rounded-tr-md items-center">
          <h2 className="text-3xl font-semibold">Truck Loading Details</h2>
          {
  localStorage.getItem('vahan-role') !== 'company' && (
    <button
      className="px-4 py-3 bg-green-500 flex items-center justify-center gap-2 text-white font-semibold rounded-lg hover:bg-green-600"
      onClick={() => setIsModalOpen(true)} // Open the truck loading modal
    >
      <FaPlus className="text-xl" />
      <span>Create New Truck Loading</span>
    </button>
  )
}
        </div>
        {/* Date Filter Section */}
        {error && <p className="mx-6 text-red-500">{error}</p>}{" "}
        {/* Error message */}
        {/* Action Section with Create Button and Icons */}
        <div className="flex flex-col gap-4 md:flex-row justify-between items-center my-6 px-6 py-6 border rounded-3xl shadow-md shadow-blue-100">
          {/* Search Input */}

          <div className="flex items-center  rounded-lg bg-[#f7f8fc]  px-4 py-4 gap-2 w-full md:w-1/3   ">
            <IoSearch className="fill-[#aaadbc] text-2xl" />

            <input
              type="text"
              placeholder="Enter Vehicle Number"
              className="border-none outline-none w-full h-full bg-transparent placeholder:text-[#868dae] text-lg font-semibold"
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>
          <div className="flex items-center space-x-4">
  <button
    onClick={() => setFilter("all")}
    className="py-2 px-6 text-lg font-semibold text-white bg-gradient-to-r from-blue-500 to-indigo-600 rounded-xl shadow-lg hover:scale-105 transform transition-all duration-300 ease-out hover:shadow-xl focus:outline-none focus:ring-2 focus:ring-blue-400"
  >
    All
  </button>
  <button
    onClick={() => setFilter("recentlyCompleted")}
    className="py-2 px-6 text-lg font-semibold text-white bg-gradient-to-r from-green-500 to-teal-600 rounded-xl shadow-lg hover:scale-105 transform transition-all duration-300 ease-out hover:shadow-xl focus:outline-none focus:ring-2 focus:ring-green-400"
  >
    Recently Completed
  </button>
  <button
    onClick={() => setFilter("recentlyRunning")}
    className="py-2 px-6 text-lg font-semibold text-white bg-gradient-to-r from-yellow-400 to-orange-500 rounded-xl shadow-lg hover:scale-105 transform transition-all duration-300 ease-out hover:shadow-xl focus:outline-none focus:ring-2 focus:ring-yellow-400"
  >
    Recently Running
  </button>
</div>


          <div className="flex w-full md:w-1/3 justify-between md:justify-end items-center gap-2">
            <div className="bg-[#f7f8fc] flex-col w-full md:w-1/3 flex gap-2 py-2 px-3 border rounded-lg ">
              <label className="text-[#868dae] text-lg font-semibold">
                From Date :
              </label>

              <input
                type="date"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
                className="bg-transparent"
                placeholder="sort by"
              />
            </div>

            <div className="bg-[#f7f8fc] flex-col w-full md:w-1/3 flex gap-2 py-2 px-3 border rounded-lg">
              <label className="text-[#868dae] text-lg font-semibold">
                To Date :
              </label>
              <input
                type="date"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
                className="bg-transparent"
              />
            </div>

            <button
              className="px-6 py-7 bg-blue-600  border shadow-md  text-white font-semibold rounded-lg hover:bg-blue-800 "
              onClick={handleFilter} // Trigger filter function
            >
              Filter
            </button>
          </div>

          {/* Action Icons */}
        </div>
        {/* Record Table */}
        <div className="rounded-3xl border my-8  shadow-md shadow-blue-100">
          <div className=" px-6 py-5 flex item-center justify-between w-full border-b">
            <h1 className="text-3xl font-semibold">Loading Details</h1>
            <div className="flex items-center space-x-4 ">
              <div
                className="flex items-center justify-center rounded-md  cursor-pointer hover:bg-yellow-200 transition"
                onClick={exportToCSV}
              >
                <FaFileCsv
                  className="text-yellow-500 text-3xl "
                  title="Export as CSV"
                />
              </div>
              <div
                className="flex items-center justify-center rounded-md cursor-pointer hover:bg-green-200 transition"
                onClick={exportToExcel}
              >
                <FaFileExcel
                  className="text-green-500 text-3xl "
                  title="Export as Excel"
                />
              </div>
              <div
                className="flex items-center justify-center rounded-md cursor-pointer hover:bg-gray-200 transition"
                onClick={exportToPDF}
              >
                <FaFilePdf className="fill-red-600 text-3xl" title="Print" />
              </div>
              <div className="flex items-center justify-center rounded-md  cursor-pointer hover:bg-gray-200 transition">
                <FaPrint className="text-black text-3xl " title="Print" />
              </div>
            </div>
          </div>

          <div className="overflow-x-auto">
            <table className="min-w-full table-auto border-collapse border">
              <thead className="bg-[#f7f8fc]">
                <tr className="border-b">
                <th className="py-3 px-6 text-left text-xl font-semibold uppercase">
                    Seriel No
                  </th>
                <th className="py-3 px-6 text-left text-xl font-semibold uppercase">
                    Vehicle No
                  </th>
                  <th className="py-3 px-6 text-left text-xl font-semibold uppercase">
                    Transporter Name
                  </th>
                  <th className="py-3 px-6 text-left text-xl font-semibold uppercase">
                    Gps Imei Number
                  </th>
                  <th className="py-3 px-6 text-left text-xl font-semibold uppercase">
                    Driver Name
                  </th>
                  <th className="py-3 px-6 text-left text-xl font-semibold uppercase">
                    Material Weight
                  </th>
                  <th className="py-3 px-6 text-left text-xl font-semibold uppercase">
                    Comment
                  </th>
                  <th className="py-3 px-6 text-left text-xl font-semibold uppercase">
                    Reason
                  </th>
                  <th className="py-3 px-6 text-left text-xl font-semibold uppercase">
                    Commented By
                  </th>
                  <th className="py-3 px-6 text-left text-xl font-semibold uppercase">
                    Comment Time
                  </th>

                  <th className="py-3 px-6 text-left text-xl font-semibold uppercase">
                    Status
                  </th>
                  <th className="py-3 px-6 text-left text-xl font-semibold uppercase">
                    Created By
                  </th>
                  <th className="py-3 px-6 text-left text-xl font-semibold uppercase">
                    Created At
                  </th>
                  <th className="py-3 px-6 text-left text-xl font-semibold uppercase">
                    Role
                  </th>
                  <th className="px-3 py-6 text-gray-600 text-xl font-semibold uppercase">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((loading,index) => (
                  <tr key={loading._id} className="border-b hover:bg-gray-50">
                    <td className="py-3 px-6 text-lg font-semibold text-gray-600">
                      {index+1}
                    </td>
                    <td className="py-3 px-6 text-lg font-semibold text-gray-600">
                      {loading.vehicleNumber}
                    </td>
                    <td className="py-3 px-6 text-lg font-semibold text-gray-600">
                      {loading.transporterName}
                    </td>
                    <td className="py-3 px-6 text-lg font-semibold text-gray-600">
                      {loading.gpsImeiNumber}
                    </td>
                    <td className="py-3 px-6 text-lg font-semibold text-gray-600">
                      {loading.driverName}
                    </td>
                    <td className="py-3 px-6 text-lg font-semibold text-gray-600">
                    {loading.netMaterialWeight ? `${loading.netMaterialWeight} ton` : "N/A"}
                    </td>
                    <td
                      className={
                        loading.comment
                          ? "py-3 px-6 text-lg font-semibold text-red-500"
                          : ""
                      }
                    >
                      {loading?.comment || "N/A"}
                    </td>

                    <td className="py-3 px-6 text-lg font-semibold text-gray-600">
                      {loading?.reason || "N/A"}
                    </td>
                    <td className="py-3 px-6 text-lg font-semibold text-gray-600">
                      {loading?.CommentedBy?.username || "N/A"}
                    </td>
                    <td className="py-3 px-6 text-lg font-semibold text-gray-600">
                      {loading.commentedAt
                        ? new Date(loading?.commentedAt).toLocaleDateString()
                        : "Guard Have Not Seen Yet"}
                      {loading?.commentedAt
                        ? new Date(
                            loading?.commentedAt || "N/A"
                          ).toLocaleTimeString()
                        : ""}
                      {/* {loading?.commentedAt || 'N/A'} {new Date(loading?.commentedAt).toLocaleDateString()}
                    {new Date(loading?.commentedAt || "N/A").toLocaleTimeString()} */}
                    </td>

                    <td className="py-3 px-6 text-lg font-semibold text-gray-600">
                      {loading.status}
                    </td>
                    <td className="py-3 px-6 text-lg font-semibold text-gray-600">
                      {loading.createdBy?.username}
                    </td>
                    <td className="py-3 px-6 text-lg font-semibold text-gray-600">
                      {new Date(loading.createdAt).toLocaleDateString()}{" "}
                      {new Date(loading.createdAt).toLocaleTimeString()}
                    </td>
                    <td className="py-3 px-6 text-lg font-semibold text-gray-600">
                      {loading.createdBy?.role}
                    </td>
                    <td className="py-3 px-2 flex items-center space-x-4">
                      <button
                        onClick={() => handleEdit(loading._id)}
                        className="flex items-center w-20"
                      >
                        <img src={Edit} alt="Edit" className="w-8 h-8" />{" "}
                        {/* Use relative size */}
                      </button>
                      <button
                        onClick={() => handleDelete(loading._id)}
                        className="flex items-center w-20"
                      >
                        <img src={Delete} alt="Delete" className="w-8 h-8" />{" "}
                        {/* Use relative size */}
                      </button>
                      <FaEye
                        className="text-blue-600 hover:text-blue-800 cursor-pointer text-xl w-20" // Use text size for icon
                        title="View Details"
                        onClick={() =>
                          navigate(`/truck-details/${loading._id}`)
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Pagination */}
          <div className="px-6 py-6 flex item-center justify-between mt-4">
            <button
              disabled={currentPage === 1}
              onClick={() => setCurrentPage((prev) => prev - 1)}
              className="text-xl font-semibold"
            >
              Previous
            </button>
            <span className="text-xl font-semibold">
              {currentPage} / {totalPages}
            </span>
            <button
              disabled={currentPage === totalPages}
              onClick={() => setCurrentPage((prev) => prev + 1)}
              className="text-xl font-semibold"
            >
              Next
            </button>
          </div>
        </div>
        <EditTruckLoadingModal
          isOpen={isEditModalOpen}
          onClose={() => setEditModalOpen(false)}
          loadingId={selectedLoadingId}
          onRefresh={refreshData}
        />
      </div>
    </>
  );
};

export default TruckLoadingTable;
